import { MenuItem } from './menu.model';
export const MenuProvi: MenuItem[] = [
  {
    id: 1,
    icon: 'bx-home-circle',
    label: 'Un Momento Por favor',
    isTitle: true
  },

];
export const MENUADMIN: MenuItem[] = [
  /*{
    id: 1,
    label: 'ROOT',
    isTitle: true
  },
  {
    id: 2,
    label: '>. SUDO SU',
    icon: 'mdi mdi-badge-account-alert-outline',
    link: 'sudo-su'
  },*/
  {
    id: 1,
    label: 'PRINCIPAL',
    isTitle: true
  },

  {
    id: 2,
    label: 'Home',
    icon: 'bx-home-circle',
    link: 'dashboard'
  },
  {
    id: 3,
    label: 'Empresa',
    icon: 'bx bx-building',
    link: 'Empresa'
  },
  {
    id: 3,
    label: 'Preferencias',
    icon: 'bx bx-cog ',
    link: 'Empresa/Preferencias'
  },
  {
    id: 4,
    label: 'PERSONAS',
    isTitle: true
  },
  {
    id: 5,
    label: 'Clientes',
    icon: 'mdi mdi-account-group',
    subItems: [
      {
        id: 1,
        label: 'Usuarios',
        link: 'Clientes/Usuarios',
        parentId: 2
      },
      {
        id: 2,
        label: 'Mapa Clientes',
        link: 'Clientes/MapaClientes',
        parentId: 2
      },
      {
        id: 4,
        label: 'Contratos',
        link: 'Clientes/Contratos',
        parentId: 2
      },

      {
        id: 5,
        label: 'Comisionista',
        link: 'Contratos/Comisionista',
        parentId: 2
      }

    ]
  },
  {
    id: 6,
    label: 'Colaboradores',
    icon: 'bx bx-fingerprint',
    subItems: [
      {
        id: 1,
        label: 'Gestion',
        link: 'Empresa/Colaboradores',
        parentId: 2
      },
      {
        id: 2,
        label: 'Mapa Colaboradores',
        link: 'Empresa/MapaColaboradores',
        parentId: 2
      }
    ]
  },

  {
    id: 7,
    label: 'NODOS',
    isTitle: true
  },

  {
    id: 8,
    label: 'OLT',
    icon: 'mdi mdi-server',
    link: 'Nodos/Olt'
  },
  {
    id: 7,
    label: 'Routers',
    icon: 'mdi mdi-router',
    subItems: [
      {
        id: 1,
        label: 'Router',
        link: 'Mikrotik/Routers',
        parentId: 2
      },
      {
        id: 1,
        label: 'Zonas',
        link: 'Mikrotik/zonas',
        parentId: 2
      },
      {
        id: 1,
        label: 'Sectoriales',
        link: 'Mikrotik/sectoriales',
        parentId: 2
      },
      {
        id: 1,
        label: 'Equipo Cliente',
        link: 'Mikrotik/equipos',
        parentId: 2
      },

    ]
  },
  {
    id: 7,
    label: 'ONU',
    icon: 'mdi mdi-server-minus',
    subItems: [
      {
        id: 1,
        label: 'Equipos',
        link: 'Nodos/EquiposOnts',
        parentId: 2
      },

      {
        id: 1,
        label: 'Cortes Masivos',
        link: 'Nodos/CorteMasivo',
        parentId: 2
      },
      {
        id: 1,
        label: 'Historial Movimientos',
        link: 'Nodos/MovimientoOnts',
        parentId: 2
      }
    ]
  },
  /*{
    id: 3,
    label: 'MikroTik',
    icon: 'mdi mdi-alpha-m-box',
    link: 'Nodos/MikroTik'
  },*/
  {
    id: 7,
    label: 'ATENCIÓN AL CLIENTE',
    isTitle: true
  },
  {
    id: 8,
    label: 'SOPORTE',
    icon: 'bx bx-support',
    subItems: [
      {
        id: 1,
        icon: 'bx bx-building',
        label: 'Nueva Solicitud',
        link: 'SoporteTecnico/Soportes',
        parentId: 2
      },
      {
        id: 3,
        label: 'Cortes de Fibra',
        link: 'SoporteTecnico/CorteFibra',
        parentId: 2
      },

    ]
  },
  {
    id: 9,
    label: 'Cajas',
    isTitle: true
  },
  {
    id: 10,
    label: 'FACTURACIÓN',
    icon: 'bx bx-receipt',
    subItems: [
      {
        id: 1,
        icon: 'bx bx-building',
        label: 'Nueva Factura',
        link: 'Billings/Ventas',
        parentId: 2
      },
      {
        id: 3,
        label: 'Planes y Servicios',
        link: 'Billings/Servicios',
        parentId: 2
      },
      {
        id: 3,
        label: 'Cuentas x Cobrar',
        link: 'Billings/CuentasXCobrar',
        parentId: 2
      },
      {
        id: 3,
        label: 'Cupones Sorteos',
        link: 'Billings/CuponesSorteo',
        parentId: 2
      },
      {
        id: 3,
        label: 'Cortes Futuros',
        link: 'Billings/CortesFuturos',
        parentId: 2
      },
      {
        id: 3,
        label: 'Débito Automático',
        link: 'Billings/DebitoAutomatico',
        parentId: 2
      },
      {
        id: 3,
        label: 'Facturación Automática',
        link: 'Billings/FacturacionAutomatica',
        parentId: 2
      }


    ]
  },
  {
    id: 11,
    label: 'CONTABLE',
    isTitle: true
  },
  {
    id: 3,
    label: 'Flota',
    icon: 'mdi mdi-car-lifted-pickup',
    link: 'Flota'
  },
  {
    id: 12,
    label: 'COMPROBANTES',
    icon: 'bx bx-dollar-circle',
    subItems: [
      {
        id: 1,
        label: 'Facturación',
        link: 'Contable/Historial/Facturas',
        parentId: 2
      },
      {
        id: 2,
        label: 'Vouchers de Pago',
        link: 'Contable/Historial/VouchersPago',
        parentId: 2
      },
      {
        id: 3,
        label: 'Retenciones Recibidas',
        link: 'Contable/Historial/RetencionesRecibidas',
        parentId: 2
      },
      /*{
        id: 3,
        label: 'Notas de Venta',
        link: 'Contable/Historial/NotasVentaList',
        parentId: 2
      },
      {
        id: 3,
        label: 'Proformas',
        link: 'Contable/Historial/Proformas',
        parentId: 2
      },
      {
        id: 3,
        label: 'Guias de Remisión',
        link: 'Contable/Historial/GuiasRemision',
        parentId: 2
      },
      {
        id: 3,
        label: 'Retenciones',
        link: 'Contable/Historial/Retenciones',
        parentId: 2
      },
      {
        id: 2,
        label: 'Reporte ATS',
        link: 'Contable/Facturacion/ATS',
        parentId: 2
      }*/
    ]
  },
  {
    id: 13,
    label: 'INVENTARIO',
    icon: 'bx bxl-dropbox',
    subItems: [
      {
        id: 145,
        label: 'Productos',
        parentId: 143,
        subItems: [

          {
            id: 2,
            label: 'Proveedores',
            link: 'Inventario/Proveedores',
            parentId: 2
          },
          {
            id: 147,
            label: 'Categorias',
            link: 'Inventario/Categorias',
            parentId: 145,
          },

          {
            id: 147,
            label: 'Marcas',
            link: 'Inventario/Marcas',
            parentId: 145,
          },
          {
            id: 146,
            label: 'Registros',
            link: 'Inventario/InventarioList',
            parentId: 145,
          },
        ]
      },
      {
        id: 2,
        label: 'Compras',
        link: 'Inventario/Compras',
        parentId: 2,
        subItems: [

          {
            id: 1,
            label: 'Ingreso Primario',
            link: 'Inventario/IngresoPrimario',
            parentId: 2
          },
          {
            id: 1,
            label: 'Acreditar',
            link: 'Inventario/ComprasPendientesAcreditar',
            parentId: 2
          },
          {
            id: 1,
            label: 'Registros',
            link: 'Inventario/Compras',
            parentId: 2
          },
        ]
      },

      {
        id: 145,
        label: 'Movimientos',
        parentId: 143,
        subItems: [

          {
            id: 1,
            label: 'Peticiones Flota',
            link: 'Inventario/Peticiones',
            parentId: 2
          },
          {
            id: 1,
            label: 'Ingresos',
            link: 'Inventario/Ingresos',
            parentId: 2
          },
          {
            id: 1,
            label: 'Egresos',
            link: 'Inventario/Egresos',
            parentId: 2
          },
        ]
      },

    ]
  },
  {
    id: 14,
    label: 'CONTABILIDAD',
    icon: 'bx bxs-report',
    subItems: [


      {
        id: 1,
        label: 'Cajas',
        link: 'Contable/Contabilidad/Cajas',
        parentId: 2
      },
      {
        id: 1,
        label: 'Plan de Cuentas',
        link: 'Contable/Contabilidad/Planes',
        parentId: 2
      },


      {
        id: 113,
        label: 'Tranferencias',
        subItems: [
          /*  {
              id: 114,
              label: 'Boveda',
              link: 'Contable/Transferencias/Boveda',
              parentId: 113
            },*/
          {
            id: 115,
            label: 'Caja Chica',
            link: 'Contable/Transferencias/CajaChica',
            parentId: 113
          },
        ]
      },
      {
        id: 2,
        label: 'Estadísticas',
        link: 'Contable/Contabilidad/Reportes',
        parentId: 2
      }
    ]
  },
  {
    id: 15,
    label: 'NOTIFICACIONES',
    isTitle: true
  },
  {
    id: 16,
    label: 'WhatsApp',
    icon: 'bx bxl-whatsapp',
    subItems: [
      {
        id: 1,
        label: 'Dispositivos',
        link: '/WhatsApp/Devices',
        parentId: 2
      },
      {
        id: 2,
        label: 'Campañas',
        link: '/WhatsApp/Campanas',
        parentId: 2
      },
      {
        id: 3,
        label: 'Historial',
        link: '/WhatsApp/Historial',
        parentId: 2
      }
    ]
  },

];
export const MENUCAJA: MenuItem[] = [

  {
    id: 4,
    label: 'PERSONAS',
    isTitle: true
  },
  {
    id: 5,
    label: 'Clientes',
    icon: 'mdi mdi-account-group',
    subItems: [
      {
        id: 1,
        label: 'Usuarios',
        link: 'Clientes/Usuarios',
        parentId: 2
      },
      {
        id: 2,
        label: 'Mapa Clientes',
        link: 'Clientes/MapaClientes',
        parentId: 2
      },
      {
        id: 4,
        label: 'Contratos',
        link: 'Clientes/Contratos',
        parentId: 2
      }
    ]
  },
  {
    id: 7,
    label: 'NODOS',
    isTitle: true
  },
  {
    id: 8,
    label: 'OLT',
    icon: 'mdi mdi-server',
    link: 'Nodos/Olt'
  },

  {
    id: 7,
    label: 'ONU',
    icon: 'mdi mdi-server-minus',
    subItems: [
      {
        id: 1,
        label: 'Equipos',
        link: 'Nodos/EquiposOnts',
        parentId: 2
      },

      {
        id: 1,
        label: 'Cortes Masivos',
        link: 'Nodos/CorteMasivo',
        parentId: 2
      }
    ]
  },

  {
    id: 7,
    label: 'ANTENCIÓN AL CLIENTE',
    isTitle: true
  },
  {
    id: 8,
    label: 'SOPORTE',
    icon: 'bx bx-support',
    link: 'SoporteTecnico/Soportes'
  },
  {
    id: 9,
    label: 'Cajas',
    isTitle: true
  },
  {
    id: 10,
    label: 'FACTURACIÓN',
    icon: 'bx bx-receipt',
    subItems: [
      {
        id: 1,
        icon: 'bx bx-building',
        label: 'Nueva Factura',
        link: 'Billings/Ventas',
        parentId: 2
      },
      {
        id: 3,
        label: 'Cuentas x Cobrar',
        link: 'Billings/CuentasXCobrar',
        parentId: 2
      }
    ]
  },
  {
    id: 11,
    label: 'CONTABLE',
    isTitle: true
  },

  {
    id: 12,
    label: 'COMPROBANTES',
    icon: 'bx bx-dollar-circle',
    subItems: [
      {
        id: 3,
        label: 'Facturación',
        link: 'Contable/Historial/Facturas',
        parentId: 2
      }
    ]
  },
  {
    id: 14,
    label: 'CONTABILIDAD',
    icon: 'bx bxs-report',
    subItems: [
      {
        id: 115,
        label: 'Caja Chica',
        link: 'Contable/Transferencias/CajaChica',
        parentId: 113
      }
    ]
  },

];

export const MENUCUADRILLA: MenuItem[] = [
  {
    id: 7,
    label: 'ATENCIÓN AL CLIENTE',
    isTitle: true
  },
  {
    id: 2,
    label: 'Home',
    icon: 'bx-home-circle',
    link: 'SoporteTecnico'
  },

  {
    id: 4,
    label: 'PERSONAS',
    isTitle: true
  },
  {
    id: 5,
    label: 'Clientes',
    icon: 'mdi mdi-account-group',
    subItems: [
      {
        id: 2,
        label: 'Mapa Clientes',
        link: 'Clientes/MapaClientes',
        parentId: 2
      },
      {
        id: 4,
        label: 'Contratos',
        link: 'Clientes/Contratos',
        parentId: 2
      },
    ]
  },

  {
    id: 7,
    label: 'ONU',
    icon: 'mdi mdi-server-minus',
    subItems: [
      {
        id: 1,
        label: 'Equipos',
        link: 'Nodos/EquiposOnts',
        parentId: 2
      },

    ]
  },
  {
    id: 7,
    label: 'ATENCIÓN AL CLIENTE',
    isTitle: true
  },
  {
    id: 8,
    label: 'SOPORTE',
    icon: 'bx bx-support',
    link: 'SoporteTecnico/Soportes'
  },
  {
    id: 9,
    label: 'Cajas',
    isTitle: true
  },
  {
    id: 10,
    label: 'FACTURACIÓN',
    icon: 'bx bx-receipt',
    subItems: [
      {
        id: 1,
        icon: 'bx bx-building',
        label: 'Nueva Factura',
        link: 'Billings/Ventas',
        parentId: 2
      }
    ]
  },
  {
    id: 11,
    label: 'CONTABLE',
    isTitle: true
  },
  {
    id: 3,
    label: 'Flota',
    icon: 'mdi mdi-car-lifted-pickup',
    link: 'Flota'
  },
  {
    id: 12,
    label: 'COMPROBANTES',
    icon: 'bx bx-dollar-circle',
    subItems: [
      {
        id: 3,
        label: 'Facturación',
        link: 'Contable/Historial/Facturas',
        parentId: 2
      }
    ]
  }
];
export const MENUESTADISTA: MenuItem[] = [
  {
    id: 14,
    label: 'CONTABILIDAD',
    icon: 'bx bxs-report',
    subItems: [
      {
        id: 2,
        label: 'Estadísticas',
        link: 'Contable/Contabilidad/Reportes',
        parentId: 2
      }
    ]
  },
];
export const MENURESELLER: MenuItem[] = [
  {
    id: 7,
    label: 'ONU',
    icon: 'mdi mdi-server-minus',
    subItems: [
      {
        id: 1,
        label: 'Equipos',
        link: 'Nodos/EquiposOnts',
        parentId: 2
      },
    ]
  },
]


export const MENUOLTPLANOLTONLY: MenuItem[] = [
  {
    id: 7,
    label: 'NODOS',
    isTitle: true
  },
  {
    id: 8,
    label: 'OLT',
    icon: 'mdi mdi-server',
    link: 'Nodos/Olt'
  },

  {
    id: 7,
    label: 'ONU',
    icon: 'mdi mdi-server-minus',
    subItems: [
      {
        id: 1,
        label: 'Equipos',
        link: 'Nodos/EquiposOnts',
        parentId: 2
      },

      {
        id: 1,
        label: 'Cortes Masivos',
        link: 'Nodos/CorteMasivo',
        parentId: 2
      }
    ]
  },
];
export const Bot_Olt_PLANOL: MenuItem[] = [
  {
    id: 1,
    label: 'PRINCIPAL',
    isTitle: true
  },
  {
    id: 3,
    label: 'Empresa',
    icon: 'bx bx-building',
    link: 'Empresa'
  },
  {
    id: 6,
    label: 'Colaboradores',
    icon: 'bx bx-fingerprint',
    subItems: [
      {
        id: 1,
        label: 'Gestion',
        link: 'Empresa/Colaboradores',
        parentId: 2
      }
    ]
  },
  {
    id: 7,
    label: 'NODOS',
    isTitle: true
  },
  {
    id: 8,
    label: 'OLT',
    icon: 'mdi mdi-server',
    link: 'Nodos/Olt'
  },

  {
    id: 7,
    label: 'ONU',
    icon: 'mdi mdi-server-minus',
    subItems: [
      {
        id: 1,
        label: 'Equipos',
        link: 'Nodos/EquiposOnts',
        parentId: 2
      },

      {
        id: 1,
        label: 'Cortes Masivos',
        link: 'Nodos/CorteMasivo',
        parentId: 2
      }
    ]
  },
  {
    id: 16,
    label: 'WhatsApp',
    icon: 'bx bxl-whatsapp',
    subItems: [
      {
        id: 1,
        label: 'Dispositivos',
        link: '/WhatsApp/Devices',
        parentId: 2
      },
      {
        id: 3,
        label: 'Historial',
        link: '/WhatsApp/Historial',
        parentId: 2
      }
    ]
  }
];