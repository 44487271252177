export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  
  BackEndApi: 'https://networkcontrol.digitalservicesexpress.click/v2/networkcontrol/',
  MediaRepositorioUPLOAD: 'https://networkcontrol.digitalservicesexpress.click/v2/networkcontrol/',
  Acs_backEnd: 'https://acsback.digitalservicesexpress.click/',
  FacturacionApi: 'https://facturacion.networkcontrol.space/v2/facturacion/',
  WhatsAppApi: 'https://wsp.networkcontrol.digitalservicesexpress.click/v2/whatsapp/',
  RobotApi: 'https://robot.networkcontrol.space/v2/robot/',

  /*
   BackEndApi:'https://stablenetworkcontrol.digitalservicesexpress.click/v2/networkcontrol/',
   MediaRepositorioUPLOAD: 'https://networkcontrol.digitalservicesexpress.click/v2/networkcontrol/',
   Acs_backEnd: 'https://acsback.digitalservicesexpress.click/',
   FacturacionApi:'https://facturacion.networkcontrol.space/v2/facturacion/',
   WhatsAppApi:'https://wsp.networkcontrol.digitalservicesexpress.click/v2/whatsapp/',
   RobotApi:'https://robot.digitalservicesexpress.click/v2/robot/',
  */
  /*
    BackEndApi: 'http://localhost:9900/v2/networkcontrol/',
    //BackEndApi: 'https://networkcontrol.digitalservicesexpress.click/v2/networkcontrol/',
    MediaRepositorioUPLOAD: 'http://localhost:9900/v2/networkcontrol/',
    //MediaRepositorioUPLOAD: 'https://networkcontrol.digitalservicesexpress.click/v2/networkcontrol/',
    //Acs_backEnd: 'https://acsback.digitalservicesexpress.click/',
    Acs_backEnd: 'http://localhost:5784/',
    //FacturacionApi: 'https://facturacion.networkcontrol.space/v2/facturacion/',
    FacturacionApi: 'http://localhost:9400/v2/facturacion/',
    WhatsAppApi: 'https://wsp.networkcontrol.digitalservicesexpress.click/v2/whatsapp/',
    RobotApi: 'https://robot.digitalservicesexpress.click/v2/robot/',
  */
  //BACKUP
  /*
  BackEndApi: 'https://corebackup.networkcontrol.space/v2/networkcontrol/',
  FacturacionApi: 'https://facturacion.networkcontrol.space/v2/facturacion/',
  WhatsAppApi: 'https://wsp.networkcontrol.digitalservicesexpress.click/v2/whatsapp/',
  RobotApi: 'https://robot.networkcontrol.space/v2/robot/',
*/
  /*
    BackEndApi: 'https://networkcontrol.digitalservicesexpress.click/v2/networkcontrol/',
    FacturacionApi: 'http://localhost:9400/v2/facturacion/',
    WhatsAppApi: 'https://wsp.networkcontrol.digitalservicesexpress.click/v2/whatsapp/',
    RobotApi: 'https://robot.digitalservicesexpress.click/v2/robot/',
  */
  /*
   BackEndApi:'http://192.168.10.111:9900/v2/networkcontrol/',
   FacturacionApi:'http://192.168.10.189:9400/v2/facturacion/',
   WhatsAppApi:'https://wsp.networkcontrol.digitalservicesexpress.click/v2/whatsapp/',
   RobotApi:'https://robot.digitalservicesexpress.click/v2/robot/',
   */
  /*
   BackEndApi:'https://networkcontrol.digitalservicesexpress.click/v2/networkcontrol/',
   FacturacionApi:'http://localhost:9400/v2/facturacion/',
   WhatsAppApi:'https://wsp.networkcontrol.digitalservicesexpress.click/v2/whatsapp/',
   RobotApi:'https://robot.networkcontrol.space/v2/robot/',
   */
  /** */
  tokenauth: 'accesskeyUser',
  PlanauthEmpresa: 'accesskeyUserLevel',
  secretPlanKeyDesc: '$^&*&SDF&*k5',

  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};
